/* global console Excel window BroadcastChannel*/

import { apiGetMnemonic, apiSetMnemonic, askMelodyApi, docOcr } from "../api/functionsEndpoints";
import { cleanWorksheetId } from "../taskpane/utils/utils";
const lineageChannel = new BroadcastChannel("lineage");

/**
 * The provided data will be handled by Melody.
 * @customfunction
 * @param {string} scopeId scopeId for melody search.
 * @param {string} question question for melody search.
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @requiresAddress
 * @returns {string} Melody search result.
 */
export async function askMelody(scopeId, question, invocation) {
  try {
    if (!scopeId) {
      return `ScopeId is required!!!`;
    }
    if (!question) {
      return `Question is required!!!`;
    }
    // https://6251-195-250-92-226.ngrok-free.app/answer?scopeId=b2ef0872-f3ca-48fd-9824-d2989b895e3e&message=What+is+the+name+of+the+Borrower%3F
    //https://6251-195-250-92-226.ngrok-free.app/answer?scopeId=b2ef0872-f3ca-48fd-9824-d2989b895e3e&message=What+is+the+name+of+the+Borrower%3F
    const response = await askMelodyApi(scopeId, question);
    /*   await Excel.run(async (context) => {
      // Get the currently active worksheet
      context.workbook.worksheets.getActiveWorksheet();

      // Get the currently active cell
      // const cell = context.workbook.getSelectedRange();
      // cell.load("address");
      const address = invocation.address;
      // Sync the context to fetch the cell properties
      await context.sync();
      const addressParts = address.split("!");
      const cellReference = addressParts.length > 1 ? addressParts[1] : address;
      // Log the cell address
      if (addressParts[1]) {
        window.cellAddress = addressParts[1];
      }
      const socket = new socketService("wss://fission-melody.cognaize.net/melody-iq-chat-dev", {
        onMessage: async (response) => {
          try {
            await Excel.run(async (context) => {
              // const sheet = context.workbook.worksheets.getActiveWorksheet();
              const sheet = context.workbook.worksheets.getItem(addressParts[0]);

              const range = sheet.getRange(address);
              context.trackedObjects.add(range);
              range.load("values");
              await context.sync();

              if (response.status === "FAIL") {
                range.values = [["Failed to query"]];
                await context.sync();
              } else {
                range.format.autofitColumns();
                range.values = [[response.message]];
                await context.sync();
                context.trackedObjects.remove(range);
                // console.log(`Updated cell ${address} to value ${concatenatedValue}`);
                sheet.load("id");
                await context.sync();
                const sheetId = cleanWorksheetId(sheet.id);
                if (response.lineage) {
                  window.storeData[sheetId] = {
                    ...(window.storeData[sheetId] || {}),
                    [cellReference]: {
                      ...(window.storeData[sheetId]
                        ? window.storeData[sheetId][cellReference] || { newCell: true }
                        : {}),
                      lineage: response.lineage,
                    },
                  };
                  lineageChannel.postMessage({
                    type: "LINEAGE_CREATED",
                    data: { cellCoordinate: cellReference, lineage: response.lineage },
                  });
                }
              }
            });
          } catch (error) {
            console.error(`Error updating cell ${address}: ${error}`);
          }
          console.log(response, "response");

          socket.closeWebSocket();
        },
      });
      //scopeId: "ffffffffffffffffffffffff"
      socket.sendMessage({ scopeId, message: value });
      console.log(`Function called from cell: ${address}`, cellReference);
      console.log(`Emitted cellSelected event with ${cellReference}`);
    });
 */
    await Excel.run(async (context) => {
      // Get the currently active worksheet
      context.workbook.worksheets.getActiveWorksheet();

      // Get the currently active cell
      // const cell = context.workbook.getSelectedRange();
      // cell.load("address");
      const address = invocation.address;
      // Sync the context to fetch the cell properties
      await context.sync();
      const addressParts = address.split("!");
      const cellReference = addressParts.length > 1 ? addressParts[1] : address;
      // Log the cell address
      if (addressParts[1]) {
        window.cellAddress = addressParts[1];
      }
      const sheet = context.workbook.worksheets.getItem(addressParts[0]);

      const range = sheet.getRange(address);

      if (response.status !== "FAIL") {
        range.format.autofitColumns();
        await context.sync();
        // context.trackedObjects.remove(range);
        // console.log(`Updated cell ${address} to value ${concatenatedValue}`);
        sheet.load("id");
        await context.sync();
        const sheetId = cleanWorksheetId(sheet.id);
        if (response.lineage) {
          window.storeData[sheetId] = {
            ...(window.storeData[sheetId] || {}),
            [cellReference]: {
              ...(window.storeData[sheetId]
                ? window.storeData[sheetId][cellReference] || { newCell: true, id: Date.now() }
                : {}),
              scopeId,
              lineage: response.lineage,
            },
          };
          lineageChannel.postMessage({
            type: "LINEAGE_CREATED",
            data: { cellCoordinate: cellReference, lineage: response.lineage },
          });
        }
      }
    });

    return response.message || "Oops something went wrong!!!";
  } catch (error) {
    console.error(error, "erororororor");
    return `Error: something went wrong!!!`;
  }
}
/**
 * The function for ocr search with provided arguments.
 * @customfunction OCR
 * @param {string} scopeId scopeId for scope of ocr search.
 * @param {number[][]} coordinates page and coordinates for ocr value.
 * @returns {string} Ocr.
 */
export async function ocr(scopeId, coordinates) {
  try {
    if (!scopeId) {
      return `ScopeId is required!!!`;
    }
    if (!coordinates) {
      return `Coordinates is required!!!`;
    }

    const coords = coordinates[0];
    if (coords.length < 5) {
      return "missing Coordinates!!";
    }
    const body = {
      page_number: coords[0],
      bounding_box: coords.slice(1, coords.length),
      scopeId,
    };
    const response = await docOcr(body);

    return response.data || response.error || "Something went wrong!!!";
  } catch (error) {
    console.error(error, "erororororor");
    return `Error: something went wrong!!!`;
  }
}

/**
 * The function is creating mnemonic.
 * @customfunction
 * @param {string} scopeId ScopeId for setting mnemonic.
 * @param {string} knowledgeGraph KnowledgeGraph for setting mnemonic.
 * @param {string} mnemonic Mnemonic.
 * @param {string} value Value for setting mnemonic.
 * @param {number[][]} coordinates page and coordinates for setting mnemonic.
 * @returns {string} Ocr.
 */
export async function setMnemonic(scopeId, knowledgeGraph, mnemonic, value, coordinates) {
  try {
    if (!scopeId) {
      return `ScopeId is required!!!`;
    }
    if (!knowledgeGraph) {
      return `knowledgeGraph is required!!!`;
    }
    if (!mnemonic) {
      return `mnemonic is required!!!`;
    }

    if (!coordinates) {
      return `Coordinates is required!!!`;
    }

    const coords = coordinates[0];
    if (coords.length < 5) {
      return "missing Coordinates!!";
    }
    if (!value) {
      return "Value is required";
    }
    const body = {
      page_number: coords[0],
      bounding_box: coords.slice(1, coords.length),
      scopeId,
      knowledgeGraph,
      mnemonic,
      value,
    };
    const response = await apiSetMnemonic(body);
    return response.status === "success" ? `Created ${mnemonic} mnemonic.` : "Error: something went wrong!!!";
  } catch (error) {
    console.error(error, "erororororor");
    return `Error: something went wrong!!!`;
  }
}

/**
 * The function is creating mnemonic.
 * @customfunction
 * @param {string} scopeId ScopeId to get mnemonic.
 * @param {string} knowledgeGraph KnowledgeGraph to get mnemonic.
 * @param {string} mnemonic Mnemonic.
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @requiresAddress
 * @returns {string[][]} MnemonicList.
 */
export async function getMnemonic(scopeId, knowledgeGraph, mnemonic, invocation) {
  try {
    if (!scopeId) {
      return `ScopeId is required!!!`;
    }
    if (!knowledgeGraph) {
      return `knowledgeGraph is required!!!`;
    }
    if (!mnemonic) {
      return `mnemonic is required!!!`;
    }

    const body = {
      scopeId,
      knowledgeGraph,
      mnemonic,
    };
    const response = await apiGetMnemonic(body);
    let arrayData = [];

    response.data.forEach((element) => {
      arrayData.push(element.value);
    });
    await Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();

      const address = invocation.address;
      const range = sheet.getRange(address);

      range.format.wrapText = true;
      context.sync();
    });
    return response.status === "success" ? [arrayData] : `Error: something went wrong!!!`;
  } catch (error) {
    console.error(error, "erororororor");
    return `Error: something went wrong!!!`;
  }
}

CustomFunctions.associate("ASKMELODY", askMelody);
CustomFunctions.associate("OCR", ocr);
CustomFunctions.associate("SETMNEMONIC", setMnemonic);
CustomFunctions.associate("GETMNEMONIC", getMnemonic);