import axios from "axios";
import axiosInstance from "./apiService";
/* global process console*/
const fissionApi = process.env.FISSION_API;
export const askMelodyApi = async (scopeId, message) => {
  const { data } = await axios.get(`${fissionApi}/melody-iq-chatrest-dev`, {
    params: {
      scopeId,
      message,
    },
  });
  return data;
};
export const docOcr = async (body) => {
  const { data } = await axios.post(`${fissionApi}/melody-iq-ocr-dev`, body);
  return data;
};

export const apiSetMnemonic = async (body) => {
  const { data } = await axiosInstance.post(`/mnemonic`, body);
  return data;
};

export const apiGetMnemonic = async (body) => {
  const { data } = await axiosInstance.post(`/mnemonic/find`, body);
  return data;
};
